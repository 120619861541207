@import "antd/dist/antd.css";
@import "@authing/guard-react18/dist/esm/guard.min.css";

body {
	font-family: 'Alibaba PuHuiTi 2.0';
}

.App {
	text-align: center;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

// 只变黑不加粗，用于释义模块例句加醋
.font-bold {
	font-weight: bold;
	color: #2D2D2D !important;
}

// 既变黑又加粗，用于其他模块词条和标题醒目
.font-bold-weight {
	color: #2D2D2D !important;
	font-weight: 500 !important;
}

.font-quotes-match {
	color: #49A370;
}

.text-dash {
	font-family: 'Source Han Sans CN';
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(360deg);
	}
}

.deep-notification {
	.ant-notification-notice-close {
		display: none;
	}

	.notification-message {
		margin: 0;
		font-size: 14px;
	}

	.notification-text-btn {
		display: inline-block;
		margin-left: 32px;
		text-decoration: underline;
		color: #1890FF;
		cursor: pointer;
	}
}

.login-notification {
	width: 296px;
}

// antd 自定义样式
.ant-spin-dot-item {
	background-color: #2d2d2d;
}

.ant-input-affix-wrapper:hover,
.ant-select-selector:hover {
	border-color: #575757 !important;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus+.ant-checkbox-inner {
	border-color: #575757 !important;
}

.ant-checkbox-checked::after {
	border-color: #575757 !important;
}

.ant-input-affix-wrapper-focused,
.ant-select-focused {
	border-color: #575757;
	// box-shadow: 0px 0px 0px 2px #D6D6D6;
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
	border-color: #575757;
	box-shadow: none;
}

.ant-btn-lg {
	height: 38px;
}

.ant-btn {
	font-family: 'Alibaba PuHuiTi 2.0';
	transition: all 0.3s cubic-bezier(0, 0, 0, 1);
}

.ant-btn-dashed:hover,
.ant-btn-dashed:focus {
	color: #2D2D2D;
	border-color: #2D2D2D;
}

.ant-btn-default {
	background: #FFFFFF;
	border: 1px solid #AAAAAA;
	border-radius: 2px;
	color: #1A1A1A;
}

.ant-btn-default:hover,
.ant-btn-default:active {
	border: 1px solid #575757;
	box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
	color: #575757;
}

.ant-btn-default:active,
.ant-btn-default:focus {
	border: 1px solid #1A1A1A;
	color: #1A1A1A;
}

.ant-btn-primary {
	background: #2D2D2D;
	border: 1px solid #2D2D2D;
}

.ant-btn-primary:hover {
	background: #575757;
	border: 1px solid #575757;
}

.ant-btn-primary:active,
.ant-btn-primary:focus {
	background: #1A1A1A;
	border: 1px solid #1A1A1A;
}

.ant-select-arrow {
	color: #2d2d2d;
}

.ant-checkbox-checked .ant-checkbox-inner {
	background-color: #FFF;
	border-color: #2D2D2D;
}

.ant-checkbox-checked .ant-checkbox-inner::after {
	border-color: #2d2d2d;
	// border: none;
}

.ant-checkbox-indeterminate .ant-checkbox-inner::after {
	background-color: #2D2D2D;
	border-color: #2D2D2D;
}

.ant-checkbox-indeterminate .ant-checkbox-inner::after {
	width: 10px;
	height: 3px;
}

.ant-checkbox-wrapper-checked {
	&.half-checkbox-checked {
		.ant-checkbox-inner {
			background-color: #fff;

			&::after {
				top: 50%;
				left: 50%;
				width: 10px;
				height: 3px;
				background-color: #2d2d2d;
				border: 0;
				transform: translate(-50%, -50%) scale(1);
				opacity: 1;
				content: ' ';
			}
		}
	}
}

.ant-checkbox-checked {
	&.ant-checkbox-indeterminate {
		.ant-checkbox-inner {
			background-color: #fff;
		}
	}
}

// 分页夹Pagination样式
.pagnination-wrapper {
	width: 100%;
	text-align: center;

	.ant-pagination-item-active {
		border: 1px solid #2D2D2D;
		border-radius: 2px;
		background-color: transparent;
	}

	.ant-pagination-item:hover a,
	.ant-pagination-item-active a {
		color: #2D2D2D;
	}

	.ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon,
	.ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon {
		color: #2D2D2D;
	}

	.ant-pagination-prev:hover .ant-pagination-item-link,
	.ant-pagination-next:hover .ant-pagination-item-link {
		color: #2D2D2D;
	}

	.ant-pagination-disabled:hover .ant-pagination-item-link {
		color: rgba(0, 0, 0, 0.25);
	}
}

input:focus::-webkit-input-placeholder {
	color: transparent;
}

.select-options {
	.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
		background-color: transparent;
		color: #2d2d2d;
	}
	
	.ant-select-item-option-selected {
		position: relative;
		background-color: transparent;
	
		&:hover {
			background: #F5F5F5;
		}
	
		.ant-select-item-option-content::after  {
			position: absolute;
			right: 15px;
			bottom: 7px;
			width: 5.71428571px;
			height: 9.14285714px;
			display: table;
			border: 2px solid #2d2d2d;
			border-top: 0;
			border-left: 0;
			transform: rotate(45deg) scale(1) translate(-50%, -50%);
			opacity: 1;
			transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
			content: ' ';
		}
	}
	
	.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
		background-color: transparent;
	}
	
	.ant-select-item-option {
		color: #7A7B7B;
	
		&:hover {
			background: #F5F5F5;
		}
	}

	.ant-select-item-option-selected {
		color: #2d2d2d;
	}
}

.auto-complete-list {
	.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
		background-color: transparent;
	}

	.ant-select-item {
		padding-left: 12px;
	}
}

.empty-sm-card {
	height: 100%;
	position: relative;

	.ant-empty-image {
		display: none;
	}
	.ant-empty-description {
		position: absolute;
		top: 50%;
		left: 50%;
		margin-left: -88px;
		margin-top: -12px;
		font-family: 'Alibaba PuHuiTi 2.0';
		font-style: normal;
		font-weight: 400;
		font-size: 16px;
		line-height: 24px;
		color: #B7B8B9;

		.anticon {
			position: relative;
    		top: 1px;
			font-size: 20px;
			margin-right: 12px;
		}
	}
}

.empty-md-card {
	height: 100%;

	.ant-empty-image {
		margin-top: 40px;
	}

	.ant-empty-description {
		font-family: 'Alibaba PuHuiTi 2.0';
		font-style: normal;
		font-weight: 400;
		font-size: 16px;
		line-height: 24px;
		color: #B7B8B9;
	}
}




// 皮肤样式
// 找词皮肤相关样式
.theme-wantwords {
	.tab-item {
		&.active {
			background-color: #5F5DBC !important;
			font-weight: 600 !important;
			color: #fff !important;

		}
	}

	.tab-item:hover {
		background-color: #EFEEF6 !important;

		&.active {
			background-color: #5F5DBC !important;
		}
	}

	.category-list {
		.category-span {
			&.selected {
				background: #EFEEF6 !important;
				border: 1px solid #5F5DBC !important;
				color: #5F5DBC !important;
			}

			&:hover {
				border: 1px solid #5F5DBC !important;
			}
		}
	}

	.example-section {
		.example-title h3:first-of-type {
			width: 404px !important;
		}

		.example-item .example-text {
			width: 404px !important;
		}
		
		.example-detail-value {
			&:hover {
				color: #5F5DBC;
			}
		}
	}

	.high-light-text {
		color: #5F5DBC !important;
	}

	.search-btn {
		background-color: #5F5DBC;
		border: 1px solid #5F5DBC;

		&:hover {
			background: #5250B5;
			border: 1px solid #5250B5;
		}

		&:active {
			background: #5250B5;
			border: 1px solid #5250B5;
			opacity: 1;
		}
	}
}

.wantwords-pos-tag {
	background: #EFEEF6;
	border: 1px solid #5F5DBC;
	width: 28px;
	height: 20px;
	padding: 0;
	border-radius: 2px;
	font-size: 12px;
	line-height: 18px;
	color: #5F5DBC;
}

.wantwords-example-tag {
	background: #5F5DBC;
	width: 28px;
	height: 20px;
	padding: 0;
	// margin-left: 7px;
	border: 1px solid #5F5DBC;
	border-radius: 2px;
	font-size: 12px;
	line-height: 18px;
	color: #fff;
	text-align: center;
}

// 找句皮肤
.theme-wantquotes {
	.tab-item {
		&.active {
			background-color: #49A370 !important;
			font-weight: 600 !important;
			color: #fff !important;

		}
	}

	.tab-item:hover {
		background-color: #EDF4F0 !important;

		&.active {
			background-color: #49A370 !important;
		}
	}

	.category-list {
		.category-span {
			&.selected {
				background: #EDF4F0 !important;
				border: 1px solid #49A370 !important;
				color: #49A370 !important;
			}

			&:hover {
				border: 1px solid #49A370 !important;
			}
		}
	}

	.example-section {
		.example-title h3:first-of-type {
			width: 300px !important;
		}

		.example-item .example-text {
			width: 300px !important;
		}

		.example-detail-value {
			&:hover {
				color: #49A370;
			}
		}
	}

	.high-light-text {
		color: #49A370 !important;
	}

	.search-btn {
		background-color: #49A370;
		border: 1px solid #49A370;

		&:active {
			background: #2A8953;
			border: 1px solid #2A8953;
			opacity: 1;
		}
	}

	
}

.theme-wantcolloc {
	.tab-item {
		&.active {
			background-color: #4A6CE1 !important;
			font-weight: 600 !important;
			color: #fff !important;

		}
	}

	.tab-item:hover {
		background-color: #ECEEF5 !important;

		&.active {
			background-color: #4A6CE1 !important;
		}
	}

	.category-list {
		.category-span {
			&.selected {
				background: #ECEEF5 !important;
				border: 1px solid #4A6CE1 !important;
				color: #4A6CE1 !important;
			}

			&:hover {
				border: 1px solid #4A6CE1 !important;
			}
		}
	}

	.search-btn {
		background-color: #4A6CE1;
		border: 1px solid #4A6CE1;

		&:active {
			background: #2A8953;
			border: 1px solid #2A8953;
			opacity: 1;
		}
	}
}