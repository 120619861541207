.page-footer {
	display: flex;
	flex-direction: row;
	align-items: center;
	width: 100%;
	min-width: 1300px !important;
	height: 256px;
	padding: 60px 120px 90px 120px;
	background-color: #F5F5F5;
	border-top: 1px solid #c2c2c2;
	overflow: hidden;

	.footer-content {
		display: flex;
		flex-flow: row nowrap;
		width: 100%;
		min-width: 1125px;
		height: 96px;
	}

	.footer-logo {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: flex-start;
		height: 96px;

		.logo-image {
			padding-right: 40px;
		}

		.copy-right p {
			margin-bottom: 0;
			font-family: 'PingFang SC';
			font-style: normal;
			font-weight: 400;
			font-size: 12px;
			line-height: 20px;
			color: #2d2d2d;
		}
	}

	.footer-link {
		display: flex;
		flex-direction: row;
		align-items: flex-end;
		justify-content: center;
		flex: 1;
		height: 96px;

		a {
			display: inline-block;
			position: relative;
			left: 16px;
			padding-bottom: 4px;
			padding-right: 16px;
			font-family: 'Alibaba PuHuiTi 2.0';
			font-style: normal;
			font-weight: 400;
			font-size: 14px;
			line-height: 22px;
			text-align: center;
			color: #1A1A1A;
			margin-right: 58px;

			&:last-of-type {
				margin-right: 0;
			}

			&:hover {
				left: 0;
				border-bottom: 1px solid #1A1A1A;
				transition: left linear .3s;
			}
		}
	}

	.footer-feedback {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: flex-start;
		width: 300px;
		height: 96px;

		.feedback-btn {
			position: relative;
			width: 100%;
			height: 54px;
			padding: 4px 8px;
			background-color: #2d2d2d;
			color: #fff;
			cursor: pointer;

			.text {
				position: absolute;
				bottom: 6px;
				left: 8px;
			}

			.anticon {
				position: absolute;
				bottom: 10px;
				right: 8px;
			}
		}
	}
}